<template>
    <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardHeader>
            <CIcon name="cil-justify-center"/>
            <strong> Planting Trees</strong>
            <div class="card-header-actions">
              <a 
                href="#" 
                class="card-header-action" 
                rel="noreferrer noopener" 
                target="_blank"
              >
                <small class="text-muted">AR</small>
              </a>
            </div>
          </CCardHeader>
          <CCardBody >
                 <div class="row">
                      <div class="form-group ml-4">
                            <button class="ml-auto mr-3 btn btn-primary btn-sm" style="height:27px" @click="addPlantModal">Add Plant</button>
                      </div>
                      <span class="ml-4 mb-2">Target Trees to be Planted: <span class="font-weight-bold">{{ addComma(totalTreesPlanted + (AppTreesPerStudent - totalTreesPlanted)) }}</span></span>
                      <span class="ml-4 mb-2">Total Trees Planted: <span class="font-weight-bold">{{ addComma(totalTreesPlanted) }}</span></span>
                       <span class="ml-4 mb-2">Total Trees to be planted: <span class="font-weight-bold"> {{ addComma(AppTreesPerStudent - totalTreesPlanted)}} </span></span>
                  </div>
                <CRow>
                  <CCol lg="12">
                        <planting :p_trees="p_trees" @updatePlantedTrees="updatePlantedTreesModal">
                            <template #header>
                                <div class="card-header-actions">
                                <a 
                                    href="https://coreui.io/vue/docs/components/nav" 
                                    class="card-header-action" 
                                    rel="noreferrer noopener" 
                                    target="_blank"
                                >
                                    <small class="text-muted">List</small>
                                </a>
                                </div>
                            </template>  
                        </planting>
                  </CCol>
                </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

        <!------------ Start- Add Plan Modal ------------>
    <div id="add-plant" class="modal fade" >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Plant</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitAddPlant">
            <div class="modal-body">
                <div class="mb-3">
                <div class="row">
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-2 font-weight-bold"><span class="text-danger">*</span>No. of Trees Planted</label>
                        <input type="number" class="form-control" v-model="addPlant.no_trees"/>
                    </div>
                    <div class="col-md-12">
                        <label class="mt-2 font-weight-bold"><span class="text-danger">*</span>Place Planted</label>
                        <input type="text" class="form-control" v-model="addPlant.place"/>
                    </div>
                    <div class="col-md-12">
                        <label class="mt-2 font-weight-bold"><span class="text-danger">*</span>Date Planted</label>
                        <input type="date" class="form-control" v-model="addPlant.date"/>
                    </div>
                     <div class="col-md-12">
                          <label class="mt-2 font-weight-bold"><span class="text-danger">*</span>Upload Picture</label>
                          <span v-if="valid_tp" class="text-right text-success ml-1">Valid file.</span>  
                          <input type="file" ref="treePic" id="tree_pic" class="form-control" @change="addPicture" />
                          <span v-if="invalid_tp" class="text-danger">Invalid file (only png,jpg and jpeg extension)</span>  
                    </div>
                  </div>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button class="btn btn-primary" type="submit" v-if="!loading">Save</button>
              <button class="btn btn-primary" type="button" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Saving...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
        <!------------ End - Add Plan Modal ------------>

        <!------------ Start- Edit Plan Modal ------------>
    <div id="edit-plant" class="modal fade" >
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit Plant</h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="submitEditPlant">
            <div class="modal-body">
                <div class="mb-3">
                <div class="row">
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                        <label class="mt-2 font-weight-bold"><span class="text-danger">*</span>No. of Trees Planted</label>
                        <input type="number" class="form-control" v-model="editPlant.no_trees"/>
                    </div>
                    <div class="col-md-12">
                        <label class="mt-2 font-weight-bold"><span class="text-danger">*</span>Place Planted</label>
                        <input type="text" class="form-control" v-model="editPlant.place"/>
                    </div>
                    <div class="col-md-12">
                        <label class="mt-2 font-weight-bold"><span class="text-danger">*</span>Date Planted</label>
                        <input type="date" class="form-control" v-model="editPlant.date"/>
                    </div>
                     <div class="col-md-12">
                          <label class="mt-2 font-weight-bold ml-1">Upload Picture</label>
                          <span v-if="valid_tp1" class="text-right text-success ml-1">Valid file.</span>  
                          <input type="file" ref="treePic1" id="tree_pic1" class="form-control" @change="addPicture1" />
                          <span v-if="invalid_tp1" class="text-danger">Invalid file (only png,jpg and jpeg extension)</span>  
                    </div>
                  </div>
                </div>
            </div>
            <!-- END OF MODAL-BODY -->
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button class="btn btn-primary" type="submit" v-if="!loading">Update</button>
              <button class="btn btn-primary" type="button" disabled v-if="loading">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Updating...
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
        <!------------ End - Edit Plan Modal ------------>
  </div>
</template>

<script>
import Planting from './component/plantingTreesTable.vue'
export default {
  name: 'planting_trees',
  components: { Planting },
  data() {
    return {
      addPlant: {
          no_trees: '',
          place: '',
          date: ''
      },
      editPlant: {
          no_trees: '',
          place: '',
          date: '',
          path: ''
      },
      loading: false,
      p_trees: [],
      totalTreesPlanted: '',
      AppTreesPerStudent: '',
      valid_tp: false,
      invalid_tp: false,
      attachment_tp: '',
      valid_tp1: false,
      invalid_tp1: false,
      attachment_tp1: ''
    }
  },
  mounted() {
      this.getPlantingTrees();
  },
  methods: {

    updatePlantedTreesModal(plant) {
      this.editPlant = { ...plant };
      $('#edit-plant').modal('show');
    },

    addPicture() {
          this.valid_tp = false;
          this.invalid_tp = false;
          $('#tree_pic').css('border-color','');
          var file = $('#tree_pic').val();
          var ext = ['jpg','png','jpeg'];
          if(file) {
          var get_ext = file.split('.');
          get_ext = get_ext.reverse();
          if($.inArray ( get_ext[0].toLowerCase(), ext) > -1) {
          
            if(this.$refs.treePic.files[0].size > 4000000) {

                  toastr.error("File size required less than 4mb.");  

                return false;
            }else{
                this.valid_tp = true;
                $('#tree_pic').css('border-color','#ddd');
                this.attachment_tp = this.$refs.treePic.files[0];
                console.log(this.attachment_tp);
                return false;
            }
          } else {
            this.invalid_tp = true;
            $('#tree_pic').css('border-color','red');
            return false;
          }
          console.log(file);
          }
    },

    addPicture1() {
          this.valid_tp1 = false;
          this.invalid_tp1 = false;
          $('#tree_pic1').css('border-color','');
          var file = $('#tree_pic1').val();
          var ext = ['jpg','png','jpeg'];
          if(file) {
          var get_ext = file.split('.');
          get_ext = get_ext.reverse();
          if($.inArray ( get_ext[0].toLowerCase(), ext) > -1) {
          
            if(this.$refs.treePic1.files[0].size > 4000000) {

                  toastr.error("File size required less than 4mb.");  

                return false;
            }else{
                this.valid_tp1 = true;
                $('#tree_pic1').css('border-color','#ddd');
                this.attachment_tp1 = this.$refs.treePic1.files[0];
                console.log(this.attachment_tp1);
                return false;
            }
          } else {
            this.invalid_tp1 = true;
            $('#tree_pic1').css('border-color','red');
            return false;
          }
          console.log(file);
          }
    },

    async getPlantingTrees() {
        try {
            const response = await axios.get('api/get-planting-trees')
            .then((response) => {
                this.p_trees = response.data.planting_trees;  
                // this.p_trees.shift();
                this.AppTreesPerStudent = response.data.getAppTreesPerStudent;
                console.log("heis", this.p_trees);

                let len = this.p_trees.length;
                let totalTreesPlantd = 0;
                for(let i=0; i<len; i++) {
                  totalTreesPlantd = totalTreesPlantd + this.p_trees[i].no_trees;
                } 
                this.totalTreesPlanted = totalTreesPlantd;  
            })
        } catch(error) {
            console.log("err", error);
            alert('Something went wrong!');
        }
    },
   formatDate(date) {
      return moment
        .utc(date, "YYYY-MM-D")
        .locale("en-us")
        .local()
        .format("MMM D YYYY dddd");
        
    },
    addPlantModal() {
      $('#add-plant').modal('show');
    },
    addComma(amount) {
      let num_parts = amount.toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return num_parts.join(".");
    },
    submitAddPlant() {
      this.loading = true;
      let formData = new FormData();
      formData.append('no_trees', this.addPlant.no_trees);
      formData.append('place', this.addPlant.place);
      formData.append('date', this.addPlant.date);

      formData.append('file_TP', this.attachment_tp);
      axios
        .post('api/add-plant', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          $('#add-plant').modal('hide');
          this.loading = false;
          this.addPlant.no_trees = '';
          this.getPlantingTrees();
          this.reset_file();
        })
        .catch((response) => {
          console.log(response);
          alert('Something went wrong!');
        });
    },
  reset_file() {
          $('#tree_pic1').val('');
          $('#tree_pic').val('');
          this.valid_tp = false;
          this.valid_tp1 = false;
    },
    submitEditPlant() {
      this.loading = true;
      let formData = new FormData();
      formData.append('no_trees', this.editPlant.no_trees);
      formData.append('place', this.editPlant.place);
      formData.append('date', this.editPlant.date);
      formData.append('plant_id', this.editPlant.plant_trees_id);
      formData.append('file_TP', this.attachment_tp1);
      formData.append('path', this.editPlant.path);

      axios
        .post('api/edit-plant', formData)
        .then((response) => {
          toastr.success(response.data.message);  
          $('#edit-plant').modal('hide');
          this.loading = false;
          this.getPlantingTrees();

        })
        .catch((response) => {
          console.log(response);
          alert('Something went wrong!');
        });
    }
  
  }
}
</script>

<style>

</style>